/* stylelint-disable */
.ct__header.ct__header--default {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: none;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 1rem 1rem;
	position: relative

}
.ct__header.ct__header--default.sticky {
	background: #fff;
	-webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
	min-height: 85px;
	position: fixed !important;
	top: 0 !important;
	width: 100%;
	z-index: 99999999
}

.ct__header.ct__header--default.sticky .ct__header--inner {
	margin: 0 !important;
	max-width: 1663px;
	max-width: 100%;
}

.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__stc {
	max-width: 80px !important;
	width: 80px;
}

.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__southcentre {
	height: auto;
	max-width: 80px;
	width: 80px;
}

.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
	height: auto;
	max-width: 100px;
	width: 100px;
}

.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__galeries {
	height: auto;
	max-width: 90px;
	width: 90px;
}

.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__quartier {
	height: auto;
	margin-right: 10px;
	max-width: 90px;
	width: 90px;
}

.ct__header.ct__header--default .ct__header--inner {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 auto;
	position: relative;
	width: 100%
}

.ct__header.ct__header--default .ct__header--inner .ct__header--inner-nav {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 auto;
	position: relative;
	width: 100%
}

.ct__header.ct__header--default .ct__header--inner .ct__header--inner-nav .ct__btn--search-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.ct__header.ct__header--default .ct__header--brand {
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content
}

.ct__header.ct__header--default .ct__header--brand .ct__header--logo {
	display: block;
	height: auto;
	max-width: 120px
}

.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__stc {
	max-width: 80px !important;
	width: 80px;
}

.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__southcentre {
	height: auto;
	max-width: 80px;
	width: 80px;
}

.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
	height: auto;
	max-width: 100px;
	width: 100px;
}

.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__galeries {
	height: auto;
	max-width: 90px;
	width: 90px;
}

.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__quartier {
	height: auto;
	max-width: 90px;
	width: 90px;
}

.ct__header.ct__header--default .ct__header--shop {
	list-style: none;
	margin: -1px 5px 0 1.75rem;
	padding: 0
}

@media (min-width: 1440px) {
	
	.ct__header.ct__header--default .ct__header--shop {
		margin: -1px 15px 0 1.75rem
	}
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link {
	z-index: 12
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .button--shopnow {
	background: #f2f2f2;
	border-radius: 100px;
	color: #25272a;
	font-size: 1.125rem;
	font-weight: 600 !important;
	height: 59px;
	letter-spacing: 0.9px;
	line-height: 19px;
	margin-right: 10px;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	padding: 0 30px;
	width: 140px
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .button--shopnow img {
	height: auto;
	width: 100%;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .button--shopnow:hover {
	background: #25272a;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .button--shopnow:hover img {
	-webkit-filter: brightness(0) invert(1);
	filter: brightness(0) invert(1);
}

@media (max-width: 1440px) {
	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .button--shopnow {
			--font-size-base: 1rem
	}
}

@media (min-width: 1440px) {

	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .button--shopnow {
		margin-right: 0
	}
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown {
	background: #fff;
	-webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	-ms-grid-columns: minmax(200px, 250px) auto;
	grid-template-columns: minmax(200px, 250px) auto;
	height: 0;
	left: 0;
	list-style: none;
	margin: 0;
	margin-left: 1.75rem;
	opacity: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	top: 98px;
	-webkit-transition: all ease-in-out 250ms;
	transition: all ease-in-out 250ms;
	z-index: -11
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .ct__header--dropdown--all {
	border-right: 1px solid rgba(36, 39, 42, 0.2);
	margin-right: min(2.5rem, 3rem)
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .ct__header--dropdown--all .header--link-all svg {
	margin-left: 10px;
	-webkit-transition: all linear 250ms;
	transition: all linear 250ms;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .ct__header--dropdown--all .header--link-all:hover svg {
	-webkit-transform: translateX(16px);
	transform: translateX(16px);
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-brands-link,
.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-stores-link {
	position: relative
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-brands-link svg, .ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-stores-link svg {
	margin-left: 10px;
	-webkit-transition: all linear 250ms;
	transition: all linear 250ms;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-brands-link.active::after, .ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-stores-link.active::after {
	content: "";
	height: 150%;
	left: 0;
	position: absolute;
	top: 0;
	width: 500px;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-brands-link:hover svg, .ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .all-stores-link:hover svg {
	-webkit-transform: translateX(16px);
	transform: translateX(16px);
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .shop-container {
	z-index: 500;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .shop-container--brands.active, .ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .shop-container--stores.active {
	display: block;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .shop-container--brands {
	background: #fff;
	display: none;
	height: calc(100% - 20px);
	padding: 12px 28px;
	position: absolute;
	right: 0;
	top: 20px;
	width: calc(100% - 255px);
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown .shop-container--stores {
	background: #fff;
	display: none;
	height: calc(100% - 20px);
	padding: 12px 28px;
	position: absolute;
	right: 0;
	top: 20px;
	width: calc(100% - 255px);
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown.menu--active {
	height: 228px;
	opacity: 1;
	overflow: visible;
	padding: 2rem;
	visibility: visible;
	z-index: 12
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown.menu--active::before {
	background: #25272a;
	content: "";
	height: 5px;
	position: absolute;
	right: 0;
	top: -5px;
	-webkit-transition: width linear 350ms;
	transition: width linear 350ms;
	width: 100%;
}

.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown::before {
	background: #25272a;
	content: "";
	height: 5px;
	position: absolute;
	right: 0;
	top: -5px;
	-webkit-transition: width linear 350ms;
	transition: width linear 350ms;
	width: 0;
}

.ct__header.ct__header--default .ct__header--availability {
	background: #fff;
	border: 0;
	display: -ms-grid;
	display: grid;
	grid-gap: 0.75rem 0;
	gap: 0.75rem 0;
	-ms-grid-columns: 1fr 225px;
	grid-template-columns: 1fr 225px;
	left: 0;
	margin: 0;
	max-height: 84px;
	max-width: 100vw;
	opacity: 0;
	padding: 1.25rem 0;
	position: absolute;
	-webkit-transition: all linear 300ms;
	transition: all linear 300ms;
	visibility: hidden;
	width: 100%;
	z-index: -1
}

.ct__header.ct__header--default .ct__header--availability p {
	font-size: 0.9rem;
	font-weight: 400;
	margin: 0 2.5rem;
}

.ct__header.ct__header--default .ct__header--availability span {
	font-size: 0.9rem;
}

.ct__header.ct__header--default .ct__header--availability .ct__header--indicator {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 600;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding-left: 1.5rem;
}

.ct__header.ct__header--default .ct__header--availability::before {
	display: none;
}

.ct__header.ct__header--default .ct__header--availability::after {
	display: none;
}

.ct__header.ct__header--default .ct__header--availability.capacity--active {
	opacity: 1;
	visibility: visible;
	z-index: 100;
}

.ct__header.ct__header--info {
	background: #f2f2f2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 1.5rem;
	position: relative;
	z-index: 5002
}

.ct__header.ct__header--info .ct__header--store-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	margin-left: 0
}

.ct__header.ct__header--info .ct__header--store-info div {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours {
	cursor: pointer;
	display: none
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .header__current-hours {
	display: none;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours p {
	font-weight: 600;
	margin: 0;
	margin-right: 1rem;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all {
	background: #fff;
	-webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	list-style: none;
	opacity: 0;
	padding: 1rem 0;
	position: absolute;
	top: 2rem;
	-webkit-transform: translate3d(-1.45rem, 0, 0);
	transform: translate3d(-1.45rem, 0, 0);
	-webkit-transition: all 175ms;
	transition: all 175ms;
	visibility: hidden;
	width: 100vw;
	z-index: 19
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all li {
	margin: 0 1rem
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all li .ct__button.button--fill.fill--grey {
	font-weight: 600 !important;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all .hours--item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 1rem 2rem;
	margin-bottom: 1.75rem
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all .hours--item strong {
	text-transform: uppercase;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all .hours--item div {
	margin-left: 4rem;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all {
	opacity: 1;
	-webkit-transform: translate3d(-1.5rem, 0, 0);
	transform: translate3d(-1.5rem, 0, 0);
	visibility: visible;
	z-index: 10
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all li {
	-webkit-animation: fadeIn 800ms ease-in both;
	animation: fadeIn 800ms ease-in both
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all li:nth-child(2) {
	-webkit-animation-delay: 100ms;
	animation-delay: 100ms;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all li:nth-child(3) {
	-webkit-animation-delay: 200ms;
	animation-delay: 200ms;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all li:nth-child(4) {
	-webkit-animation-delay: 300ms;
	animation-delay: 300ms;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all li:nth-child(5) {
	-webkit-animation-delay: 400ms;
	animation-delay: 400ms;
}

.ct__header.ct__header--info .ct__header--store-info .header__mall-capacity {
	display: none;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--availability {
	display: none;
	margin: 0 2rem;
	margin-right: 0;
	padding: 0 2rem;
	padding-right: 0
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--availability p {
	font-weight: 600;
	margin: 0;
	margin-right: 1rem;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--availability::before {
	background: #a59f99;
	content: "";
	display: block;
	height: 1.125rem;
	-webkit-transform: translateX(-2rem);
	transform: translateX(-2rem);
	width: 1px;
}

.ct__header.ct__header--info .ct__header--store-info .header__capacity {
	display: none;
	margin-left: 2rem;
	padding-left: 2rem
}

.ct__header.ct__header--info .ct__header--store-info .header__capacity::before {
	background: #a59f99;
	content: "";
	display: block;
	height: 1.125rem;
	-webkit-transform: translateX(-2rem);
	transform: translateX(-2rem);
	width: 1px;
}

.ct__header.ct__header--info .ct__header--store-info .header__capacity p {
	font-weight: 600;
	margin: 0;
	margin-right: 1rem;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links {
	margin-left: auto
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--logo {
	display: block;
	height: auto;
	max-width: 94px;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .button--outline {
	margin: 0 0.325rem;
}
.ct__header.ct__header--info .ct__header--store-info .ct__header--links .button--outline:last-of-type .ct__header--logo {
	height: auto;
	max-height: 35px;
	max-width: 147px;
	padding: 0 5px;
	width: auto;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__logo {
	display: none;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--social {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0.25rem 0.5rem 0 0
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--social .menu--social {
	display: none;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .header__language {
	margin: 0;
	margin-left: 1.75rem;
}

.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__button {
	font-weight: 600;
	text-transform: uppercase;
}

.ct__header.ct__header--info .ct__header--mobile {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.ct__header.ct__header--info .ct__header--mobile.sticky {
	background: #fff;
	-webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
	position: fixed !important;
	top: 0 !important;
	width: 100%;
	z-index: 999999;
}

.ct__header.ct__header--info .ct__header--mobile .ct__header--store-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.ct__header.ct__header--info .ct__header--mobile .ct__header--store-info .header__mall-capacity {
	border-left: 1px solid #b0aeaf;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 1.25rem;
	padding-left: 1.25rem;
}

.ct__header.ct__header--info .ct__header--mobile .ct__header--store-info .ct__header--hours {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.ct__header.ct__header--mobile {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 1rem 1.5rem
}

.ct__header.ct__header--mobile .ct__header--logo {
	width: 98px;
}

.ct__header.ct__header--mobile .ct__header--shop {
	margin: 0;
	margin-left: auto;
	margin-right: 0
}

.ct__header.ct__header--mobile .ct__header--shop .ct__header--dropdown {
	width: 100%
}

.ct__header.ct__header--mobile .ct__header--shop .ct__header--dropdown.menu--active {
	opacity: 1;
	-webkit-transform: translate3d(-100%, 500px, 0);
	transform: translate3d(-100%, 500px, 0);
	visibility: visible;
	z-index: 10;
}

.ct__header.ct__header--mobile.ct__header--default {
	-webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	z-index: 299;
}

.ct__header.ct__header--mobile.ct__header--info {
	background: #f2f2f2;
	position: relative;
	z-index: 15
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info {
	width: 100%;
	z-index: 15
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity {
	border-left: 1px solid #b0aeaf;
	margin-left: 1.25rem;
	padding-left: 1.25rem
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability {
	background: #fff;
	border: 0;
	display: -ms-grid;
	display: grid;
	grid-gap: 0.75rem 0;
	gap: 0.75rem 0;
	-ms-grid-columns: 1fr 225px;
	grid-template-columns: 1fr 225px;
	left: 0;
	margin: 0;
	max-height: 84px;
	max-width: 100vw;
	opacity: 0;
	padding: 1.25rem 0;
	position: absolute;
	-webkit-transform: translate3d(0, -300px, 0);
	transform: translate3d(0, -300px, 0);
	-webkit-transition: all linear 500ms;
	transition: all linear 500ms;
	visibility: hidden;
	width: 100%;
	z-index: -1
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability p {
	font-size: 0.9rem;
	font-weight: 400;
	margin: 0 2.5rem;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability span {
	font-size: 0.9rem;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability .ct__header--indicator {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 600;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding-left: 1.5rem;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability::before {
	display: none;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability::after {
	display: none;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .header__mall-capacity .ct__header--availability.capacity--active {
	opacity: 1;
	-webkit-transform: translate3d(0, 66px, 0);
	transform: translate3d(0, 66px, 0);
	visibility: visible;
	z-index: 10;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .ct__header--hours.menu--active .ct__button.button--capacity {
	font-weight: 700;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .ct__header--hours.menu--active .ct__button.button--capacity svg path {
	-webkit-transition: all linear 250ms;
	transition: all linear 250ms;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .ct__header--hours.menu--active .ct__button.button--capacity svg path:nth-child(1) {
	opacity: 0;
}

.ct__header.ct__header--mobile.ct__header--info .ct__header--store-info .ct__header--hours.menu--active .ct__button.button--capacity svg path:nth-child(2) {
	opacity: 1;
}

.ct__header.ct__header--menu-mobile {
	background: rgba(37, 39, 42, 0.9);
	opacity: 0;
	padding-right: 70px;
	position: fixed;
	-webkit-transition: opacity linear 175ms;
	transition: opacity linear 175ms;
	width: 100%;
	z-index: -1
}

.ct__header.ct__header--menu-mobile .ct__menu--main-mobile {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	position: relative;
}

.ct__header.ct__header--menu-mobile.menu--active {
	height: calc(100vh - 155px);
	opacity: 1;
	overflow: scroll;
	visibility: visible;
	z-index: 5000
}

.ct__header.ct__header--menu-mobile.menu--active.scrolling-top-position {
	height: calc(100vh - 70px);
}

.ct__header.ct__header--menu-mobile.menu--active .ct__menu--shop {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	opacity: 1;
	padding: 2rem 2rem 6rem;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.ct__header.ct__header--menu-mobile.menu--active .ct__menu--main-mobile {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	visibility: visible;
	z-index: 5000;
}

.ct__header.ct__header--menu-mobile .button--shop-close {
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	z-index: 15;
}

.ct__header.ct__header--mobile-extra.active {
	display: none;
}

.ct__header.ct__header--mobile-extra.foodmarket .ct__header--logo {
	max-height: 35px !important;
	max-width: 146px !important;
}

.ct__header.ct__header--mobile-extra .ct__header--links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.ct__header.ct__header--mobile-extra .ct__header--links .ct__button {
	margin: 1.5rem 0;
	padding: 0;
}

.ct__header.ct__header--mobile-extra .ct__header--links .ct__button:first-of-type {
	margin-right: 1rem;
}

.ct__header.ct__header--mobile-extra .ct__header--links .ct__header--logo {
	display: block;
	height: auto;
	max-height: 100%;
	max-width: 120px;
	width: auto;
}
.ct__header.header--am {
	background-color: rgb(242, 242, 242);
	border-bottom: 1px solid rgb(229, 229, 229);
	-webkit-box-shadow: rgba(0, 0, 0, 0.15) 0 2px 5px;
	box-shadow: rgba(0, 0, 0, 0.15) 0 2px 5px;
	color: #000;
	font-size: 16px;
	height: 86px;
	margin-bottom: 32px;
	padding: 0 72px;
	position: relative;
	scroll-behavior: smooth
}

.ct__header.header--am .inner--menu {
	-webkit-box-sizing: content-box;
			box-sizing: content-box;
	margin: 0 auto -30px;
	max-width: 1663px;
	overflow-x: scroll;
	padding-bottom: 30px;
	scroll-behavior: smooth;
	text-align: center
}

.ct__header.header--am .inner--menu::-webkit-scrollbar {
	display: none !important;
}

.ct__header.header--am .inner--menu .close--hover-menu-btn {
	background: transparent;
	border: none;
	bottom: -55px;
	display: none;
	height: 20px;
	padding: 0;
	position: absolute;
	right: 30px;
	width: 20px;
	z-index: 100
}

.ct__header.header--am .inner--menu .close--hover-menu-btn svg {
	height: 20px;
	width: 20px;
}

.ct__header.header--am .inner--menu .close--hover-menu-btn.active {
	display: block;
}

.ct__header.header--am .inner--menu .close--hover-menu-btn:hover {
	cursor: pointer;
}

.ct__header.header--am .inner--menu .move--menu-left-btn,
.ct__header.header--am .inner--menu .move--menu-right-btn {
	-ms-flex-align: center;
	align-items: center;
	background: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	border: none;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 20px;
	-ms-flex-pack: center;
	justify-content: center;
	opacity: 0;
	padding: 0;
	position: absolute;
	top: 50%;
	-webkit-transition: opacity 0.3s ease 0s;
	transition: opacity 0.3s ease 0s;
	width: 20px;
	z-index: 1
}

.ct__header.header--am .inner--menu .move--menu-left-btn svg, .ct__header.header--am .inner--menu .move--menu-right-btn svg {
	height: 20px;
	width: 15px;
}

@media (max-width: 1104px) {
	.ct__header.header--am .inner--menu .move--menu-left-btn.active, .ct__header.header--am .inner--menu .move--menu-right-btn.active {
		opacity: 1;
	}
}

@media (max-width: 768px) {
	.ct__header.header--am .inner--menu .move--menu-left-btn.active, .ct__header.header--am .inner--menu .move--menu-right-btn.active {
		opacity: 0;
	}
}

@media (max-width: 635px) {
	.ct__header.header--am .inner--menu .move--menu-left-btn.active, .ct__header.header--am .inner--menu .move--menu-right-btn.active {
		opacity: 1;
	}
}

.ct__header.header--am .inner--menu .move--menu-left-btn {
	left: 8px;
	-webkit-transform: translateY(-50%) rotate(90deg);
	transform: translateY(-50%) rotate(90deg);
}

.ct__header.header--am .inner--menu .move--menu-right-btn {
	right: 8px;
	-webkit-transform: translateY(-50%) rotate(270deg);
	transform: translateY(-50%) rotate(270deg);
}

.ct__header.header--am .inner--menu .top--level {
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-align: center;
	-webkit-box-flex: 1;
	-webkit-box-pack: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 86px;
	-ms-flex-pack: center;
	justify-content: center
}

.ct__header.header--am .inner--menu .top--level > li > a {
	border-bottom: 2px solid transparent;
	border-top: 2px solid transparent;
	color: #000;
	cursor: pointer;
	display: block;
	font-size: 16px;
	height: 100%;
	line-height: 82px;
	margin-right: 70px;
	text-decoration: none;
	-webkit-transition: opacity 0.3s ease 0s;
	transition: opacity 0.3s ease 0s;
	white-space: nowrap
}

.ct__header.header--am .inner--menu .top--level > li > a:hover,
.ct__header.header--am .inner--menu .top--level > li > a:focus {
	border-bottom-color: #000;
	opacity: 1;
}

.ct__header.header--am .inner--menu .top--level > li:last-child > a {
	margin-right: 0;
}

.ct__header.header--am .inner--menu .top--level > li:nth-child(6):hover .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(6):focus .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(6).active .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(7):hover .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(7):focus .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(7).active .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(8):hover .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(8):focus .sub--level, .ct__header.header--am .inner--menu .top--level > li:nth-child(8).active .sub--level {
	border-bottom: none;
	-webkit-box-shadow: none;
			box-shadow: none;
	height: 0;
	padding: 0;
}

.ct__header.header--am .inner--menu .top--level > li .sub--level {
	background-color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	height: 0;
	left: 0;
	margin: 0 auto;
	overflow: hidden;
	position: absolute;
	top: 100%;
	-webkit-transition: height 0.3s ease 0s;
	transition: height 0.3s ease 0s;
	width: 100%;
	z-index: 10
}

.ct__header.header--am .inner--menu .top--level > li .sub--level > li {
	text-align: left;
	width: 20%
}
.ct__header.header--am .inner--menu .top--level > li .sub--level > li > a {
	border-bottom: 2px solid transparent;
	border-top: 2px solid transparent;
	color: #000;
	display: block;
	font-weight: 600;
	line-height: 32px;
	margin-bottom: 5px;
	text-decoration: none;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content
}
.ct__header.header--am .inner--menu .top--level > li .sub--level > li > a:hover,
.ct__header.header--am .inner--menu .top--level > li .sub--level > li > a:focus {
	border-bottom-color: #000;
}

.ct__header.header--am .inner--menu .top--level > li .sub--level > li .sub--sub-level > li {
	display: block;
	margin-bottom: 5px;
	text-align: left
}

.ct__header.header--am .inner--menu .top--level > li .sub--level > li .sub--sub-level > li > a {
	border-bottom: 2px solid transparent;
	border-top: 2px solid transparent;
	color: #000;
	display: inline;
	line-height: 28px;
	text-decoration: none;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content
}

.ct__header.header--am .inner--menu .top--level > li .sub--level > li .sub--sub-level > li > a:hover,
.ct__header.header--am .inner--menu .top--level > li .sub--level > li .sub--sub-level > li > a:focus {
	border-bottom-color: #000;
}

.ct__header.header--am .inner--menu .top--level > li:hover .sub--level, .ct__header.header--am .inner--menu .top--level > li:focus .sub--level, .ct__header.header--am .inner--menu .top--level > li.active .sub--level {
	border-bottom: 1px solid rgb(229, 229, 229);
	-webkit-box-shadow: rgba(0, 0, 0, 0.15) 0 2px 5px;
	box-shadow: rgba(0, 0, 0, 0.15) 0 2px 5px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	padding: 20px 72px;
}

.ct__header.header--am .inner--menu .top--level.active > li > a {
	opacity: 0.4
}

.ct__header.header--am .inner--menu .top--level.active > li > a.active {
	border-bottom-color: #000;
	opacity: 1;
}


.ct__button {
	background: transparent;
	border: none;
	border-radius: 0;
	color: #25272a;
	cursor: pointer;
	padding: 0;
	position: relative;
}

header .wpml-ls-legacy-list-horizontal {
	padding: 0 !important
}

header .wpml-ls-legacy-list-horizontal a {
	padding: 0 !important;
}

header .wpml-ls-legacy-list-horizontal .wpml-ls-native,
header .wpml-ls-legacy-list-horizontal .wpml-ls-bracket {
	display: none;
}

.ct__indicator {
	border-radius: 100%;
	height: 10px;
	margin-right: 0.5rem;
	width: 10px
}
.ct__indicator.ct__indicator--parking.indicator--yellow {
	background: #eace5b;
}
.ct__indicator.ct__indicator--parking.indicator--green {
	background: #849e1a;
}
.ct__indicator.ct__indicator--parking.indicator--red {
	background: #a85b6d;
}
.ct__indicator.ct__indicator--capacity.indicator--yellow {
	background: #eace5b;
}
.ct__indicator.ct__indicator--capacity.indicator--green {
	background: #849e1a;
}
.ct__indicator.ct__indicator--capacity.indicator--red {
	background: #a85b6d;
}
.ct__menu.ct__menu--mobile .ct__menu--item {
	color: #25272a;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 1.25px;
}

.ct__menu.ct__menu--mobile li:last-child .ct__menu--item {
	font-size: 1.125rem;
	height: 60px
}
.ct__menu.ct__menu--mobile li:last-child .ct__menu--item svg {
	margin-left: 1rem
}

.ct__menu.ct__menu--mobile li:last-child .ct__menu--item svg path {
	stroke: #fff !important;
}

.ct__menu.ct__menu--main-mobile {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: calc(100vh - 180px);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 0;
	opacity: 0;
	overflow: scroll;
	padding-top: 0;
	position: fixed;
	-webkit-transform: translate3d(0, -999px, 0);
	transform: translate3d(0, -999px, 0);
	-webkit-transition: all linear 500ms;
	transition: all linear 500ms;
	visibility: hidden;
	width: 100%;
	z-index: 9
}

.ct__menu.ct__menu--main-mobile.mobile-menu--active {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	visibility: visible;
	z-index: 5000;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	overflow: scroll;
	padding: 1rem 2rem;
	position: relative;
	width: 100%
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item {
	background: #fff;
	border-top: 1px solid #f2f2f2;
	position: relative;
	-webkit-transition: all ease-in-out 500ms;
	transition: all ease-in-out 500ms;
	z-index: 10
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item:last-child {
	border-bottom: 1px solid #f2f2f2;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #25272a;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-family: neue-haas-grotesk-display, sans-serif;
	font-size: 1rem;
	font-weight: 700;
	height: 40px;
	letter-spacing: 0.8px;
	position: relative;
	z-index: 10;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children {
	height: auto;
	position: relative;
	z-index: 10
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children::after {
	background: url("../svg/chevron-down.svg");
	background-repeat: no-repeat;
	background-size: 8px 5px;
	content: "";
	height: 5px;
	position: absolute;
	right: 0.75rem;
	top: 1.125rem;
	width: 8.5px;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children:last-of-type li {
	opacity: 0;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children a {
	position: relative;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	z-index: 10;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu {
	margin-left: 0.6rem;
	opacity: 1;
	position: relative
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item {
	-webkit-animation: fadeIn 300ms ease-in both;
	animation: fadeIn 300ms ease-in both;
	border-top: 0;
	height: 41px;
	opacity: 0
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(2) {
	-webkit-animation-delay: 50ms;
			animation-delay: 50ms;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(3) {
	-webkit-animation-delay: 100ms;
			animation-delay: 100ms;
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(4) {
	-webkit-animation-delay: 150ms;
			animation-delay: 150ms;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(5) {
	-webkit-animation-delay: 200ms;
			animation-delay: 200ms;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(6) {
	-webkit-animation-delay: 250ms;
			animation-delay: 250ms;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(7) {
	-webkit-animation-delay: 300ms;
			animation-delay: 300ms;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item:nth-child(8) {
	-webkit-animation-delay: 350ms;
			animation-delay: 350ms;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item a {
	color: #25272a;
	font-size: 0.9rem;
	font-weight: 400;
	letter-spacing: 0.7px
}

.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children .sub-menu .menu-item a::after {
	background: none;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children.menu--active:last-child {
	border-bottom: 1px solid #fff;
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children.menu--active a {
	position: relative
}
.ct__menu.ct__menu--main-mobile .ct__menu--navigation .menu-item.menu-item-has-children.menu--active a::after {
	background: url("../svg/line.svg");
	content: "";
	height: 1px;
	position: absolute;
	right: 0.75rem;
	top: 1.125rem;
	width: 8px;
}

.ct__menu.ct__menu--main {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	z-index: 50
}

.ct__menu.ct__menu--main .ct__menu--navigation {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	padding: 0
}

.ct__menu.ct__menu--main .ct__menu--navigation#menu-main-menu-fr li {
	text-align: center
}

.ct__menu.ct__menu--main .ct__menu--navigation#menu-main-menu-fr li a {
	text-align: center;
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item {
	margin: 26px max(0.75rem, 1rem) 0;
	padding-bottom: 26px;
	position: relative
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item a::after {
	background: #a59f99;
	content: "";
	display: block;
	height: 2px;
	-webkit-transform: translateY(0.25rem);
	transform: translateY(0.25rem);
	-webkit-transition: width linear 175ms;
	transition: width linear 175ms;
	width: 0;
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item a:hover::after {
	width: 100%;
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item:first-child {
	margin-left: 1.75rem;
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item:last-child {
	margin-right: 0;
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children {
	display: inline-block;
	position: relative;
	z-index: 14
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children .sub-menu {
	background: #fff;
	border: 1px solid #f2f2f2;
	-webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16078);
	display: -ms-grid;
	display: grid;
	grid-gap: 2rem 3rem;
	gap: 2rem 3rem;
	-ms-grid-columns: 175px;
	grid-template-columns: 175px;
	height: 0;
	left: 0;
	list-style: none;
	opacity: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	top: 2.15rem;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transition: opacity 175ms ease-in, -webkit-transform 400ms;
	transition: opacity 175ms ease-in, -webkit-transform 400ms;
	transition: opacity 175ms ease-in, transform 400ms;
	transition: opacity 175ms ease-in, transform 400ms, -webkit-transform 400ms;
	visibility: hidden;
	z-index: -1
}

.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children .sub-menu li {
	margin: 0;
	-webkit-transition: opacity 350ms ease-in;
	transition: opacity 350ms ease-in;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children .sub-menu li.menu-item {
	opacity: 0;
	padding-bottom: 0
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children .sub-menu li.menu-item a::after {
	display: none;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:focus {
	outline: 1px dotted #969293;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu, .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu {
	height: auto;
	left: 0;
	opacity: 1;
	overflow: visible;
	padding: 2rem;
	-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
	visibility: visible;
	z-index: 9999
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li, .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li {
	-webkit-animation: fadeIn 1s ease-in both;
			animation: fadeIn 1s ease-in both;
	opacity: 1;
	text-align: left !important
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(2), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(2) {
	-webkit-animation-delay: 50ms;
			animation-delay: 50ms;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(3), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(3) {
	-webkit-animation-delay: 100ms;
			animation-delay: 100ms;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(4), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(4) {
	-webkit-animation-delay: 150ms;
			animation-delay: 150ms;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(5), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(5) {
	-webkit-animation-delay: 200ms;
			animation-delay: 200ms;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(6), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(6) {
	-webkit-animation-delay: 250ms;
			animation-delay: 250ms;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(7), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(7) {
	-webkit-animation-delay: 300ms;
			animation-delay: 300ms;
}
.ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children:hover .sub-menu li:nth-child(8), .ct__menu.ct__menu--main .ct__menu--navigation .menu-item-has-children.focus .sub-menu li:nth-child(8) {
	-webkit-animation-delay: 350ms;
			animation-delay: 350ms;
}
.ct__menu.ct__menu--shop {
	display: -ms-grid;
	display: grid;
	grid-gap: 3rem 0.2rem;
	gap: 3rem 0.2rem;
	-ms-grid-columns: (auto)[4];
	grid-template-columns: repeat(4, auto);
	list-style: none;
	margin: 0;
	padding: 0
}
.ct__menu.ct__menu--shop a {
	color: #25272a;
	font-size: 1rem;
}
.ct__menu.ct__menu--shop li {
	position: relative
}
.ct__menu.ct__menu--shop li a {
	position: absolute;
	-webkit-transition: all ease-in 75ms;
	transition: all ease-in 75ms
}
.ct__menu.ct__menu--shop li a:hover {
	font-weight: 600;
}
.ct__menu.ct__menu--shop.menu--mobile {
	background: #25272a;
	grid-gap: 0;
	gap: 0;
	-ms-grid-columns: auto;
	grid-template-columns: auto;
	opacity: 0;
	padding: 2rem;
	position: relative;
	-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
	-webkit-transition: all linear 450ms;
	transition: all linear 450ms;
	-webkit-transition-delay: 350ms;
			transition-delay: 350ms;
	width: 100%;
	z-index: 11
}

.ct__menu.ct__menu--shop.menu--mobile li {
	border-top: 2px solid #707070;
	height: 40px
}

.ct__menu.ct__menu--shop.menu--mobile li a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 40px;
}

.ct__menu.menu--social {
	border-right: 1px solid #a59f99;
	list-style: none;
	margin: 0;
	padding: 0;
	padding-right: 1.75rem;
	position: relative
}

.ct__menu.menu--social .svg--arrow {
	margin-left: 1.15rem;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition: all linear 250ms;
	transition: all linear 250ms;
}

.ct__menu.menu--social .menu--social-dropdown {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #f2f2f2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: row !important;
	flex-direction: row !important;
	height: 0;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	left: 0;
	list-style: none;
	margin: 0;
	opacity: 0;
	overflow: hidden;
	padding: 1rem 0.9rem;
	position: absolute;
	-webkit-transition: all linear 250ms;
	transition: all linear 250ms;
	z-index: -1
}

.ct__menu.menu--social .menu--social-dropdown a {
	display: none;
}

.ct__menu.menu--social .menu--social-dropdown .icons {
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
		-moz-column-gap: 15px;
			column-gap: 15px;
	display: -ms-grid;
	display: grid;
	margin: 0 auto;
	grid-row-gap: 15px;
	row-gap: 15px;
	width: 100%
}

.ct__menu.menu--social .menu--social-dropdown .icons.one {
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons.two {
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons.three {
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons.four {
	-ms-grid-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons.five {
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons.six {
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons.seven {
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.ct__menu.menu--social .menu--social-dropdown .icons .icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #25272a;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}
.ct__menu.menu--social .menu--social-dropdown .icons .icon svg {
	height: 100%;
	width: auto;
}
.ct__menu.menu--social .menu--social-dropdown .icons .icon a:hover svg {
	opacity: 0.4;
}
.ct__menu.menu--social .menu--social-dropdown li {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0;
}
.ct__menu.menu--social .menu--social-dropdown::before {
	content: "";
	left: 0;
	padding-top: 20px;
	position: absolute;
	top: 0;
	width: 100%;
}
.ct__menu.menu--social:hover .svg--arrow, .ct__menu.menu--social:focus .svg--arrow, .ct__menu.menu--social.active .svg--arrow {
	-webkit-transform: rotate(0);
	transform: rotate(0);
}
.ct__menu.menu--social:hover .menu--social-dropdown, .ct__menu.menu--social:focus .menu--social-dropdown, .ct__menu.menu--social.active .menu--social-dropdown {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	opacity: 1;
	overflow: visible;
	-webkit-transform: translate3d(0, 12px, 0);
	transform: translate3d(0, 12px, 0);
	z-index: 10
}

.ct__menu.menu--social:hover .menu--social-dropdown a, .ct__menu.menu--social:focus .menu--social-dropdown a, .ct__menu.menu--social.active .menu--social-dropdown a {
	display: unset;
}

@media screen and (min-width: 364px) {
	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--social {
		margin: 0 0.5rem 0 0;
	}
}

@media screen and (min-width: 375px) {

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__stc {
		max-width: 110px !important;
		width: 110px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		max-width: 120px;
		width: 120px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
		max-width: 140px;
		width: 140px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__quartier {
		max-width: 100px;
		width: 100px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__stc {
		max-width: 110px !important;
		width: 110px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		max-width: 120px;
		width: 120px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
		max-width: 140px;
		width: 140px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__quartier {
		max-width: 100px;
		width: 100px;
	}
}
@media screen and (min-width: 380px) {

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--social {
		margin: 0.25rem 1.5rem 0;
	}
}

@media screen and (min-width: 400px) {
	
	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		max-width: 140px;
		width: 140px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
		max-width: 140px;
		width: 140px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__galeries {
		max-width: 120px;
		width: 120px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		max-width: 140px;
		width: 140px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
		max-width: 140px;
		width: 140px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__galeries {
		max-width: 120px;
		width: 120px;
	}
}
@media screen and (min-width: 406px) {

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--social {
		margin: 0 1.5rem;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .header__language {
		border-left: 1px solid #a59f99;
		margin: 0;
		padding-left: 1.75rem;
	}
}

@media screen and (min-width: 600px) {
	
	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		max-width: 210px;
		width: 210px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
		margin-left: -20px;
		max-width: 210px;
		width: 210px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__galeries {
		max-width: 180px;
		width: 180px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__quartier {
		max-width: 120px;
		width: 120px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		max-width: 210px;
		width: 210px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__uppercanada {
		max-width: 210px;
		width: 210px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__galeries {
		max-width: 180px;
		width: 180px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__quartier {
		max-width: 120px;
		width: 120px;
	}
}
@media screen and (min-width: 768px) {

	.ct__header.ct__header--info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-left: 2rem;
		padding-right: 2rem
	}

	.ct__header.ct__header--info .ct__header--store-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin: 0;
		margin-right: auto
	}

	.ct__header.ct__header--info .ct__header--store-info div,
	.ct__header.ct__header--info .ct__header--store-info .header__capacity {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
				
	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .ct__header--hours--all {
		padding-left: 0;
		padding-right: 0;
		width: 360px;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours svg {
		height: 5px;
		margin-left: 0
	}
		
	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours svg path:nth-child(2) {
		opacity: 0;
	}
	
	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours.menu--active svg path {
		-webkit-transition: all linear 250ms;
		transition: all linear 250ms;
	}
		
	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours.menu--active svg path:nth-child(1) {
		opacity: 0;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours.menu--active svg path:nth-child(2) {
		opacity: 1;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all {
		top: 2rem;
		-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
	}
		
	.ct__header.ct__header--info .ct__header--store-info .header__mall-capacity {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--availability {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.ct__header .ct__header--mobile {
		display: none !important;
	}

	.ct__header.ct__header--default {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.ct__header.ct__header--menu-mobile.menu--active .ct__menu--shop {
		height: 100%;
	}
}

@media screen and (min-width: 992px) {

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .header__current-hours {
		display: block;
	}

	.ct__header.ct__header--info .ct__header--store-info .arrow--down {
		display: none;
	}
}

@media screen and (min-width: 1200px) {

	.ct__header.ct__header--default {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 174px;
		padding-left: 0;
		padding-right: 0
	}

	.ct__header.ct__header--default.sticky {
		height: 90px;
		padding-left: 0;
		padding-right: 0
	}
	
	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo {
		max-width: 100px
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__stc {
		max-width: 120px !important;
		width: 120px;
	}

	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		height: auto;
		max-width: 190px;
		width: 190px;
	}
	
	.ct__header.ct__header--default.sticky .ct__header--brand .ct__header--logo.mall-logo__galeries {
		max-width: 180px;
		width: 180px;
	}

	.ct__header.ct__header--default.sticky .ct__header--inner {
		height: 90px;
		padding: 0 4.5rem;
		-webkit-transition: height 0.1s ease-in-out;
		transition: height 0.1s ease-in-out;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__stc {
		max-width: 120px !important;
		width: 120px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__southcentre {
		height: auto;
		max-width: 190px;
		width: 190px;
	}

	.ct__header.ct__header--default .ct__header--brand .ct__header--logo.mall-logo__galeries {
		max-width: 180px;
		width: 180px;
	}

	.ct__header.ct__header--default .ct__header--inner {
		margin: 0 4.5rem;
	}

	.ct__header.ct__header--default .ct__header--shop {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: unset
	}
		
	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__button.button--fill {
		font-weight: 500 !important;
	}

	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown {
		display: -ms-grid;
		display: grid;
		top: 134px;
		width: 95%
	}

	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown.nav--scrolling-top {
		top: -2px !important;
	}

	.ct__header.ct__header--default .form--search {
		display: none;
	}

	.ct__header.ct__header--info {
		padding-left: 0;
		padding-right: 0
	}

	.ct__header.ct__header--info .ct__header--store-info {
		margin: 0 4.5rem;
		width: 100%
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__logo {
		display: block;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .ct__header--social .menu--social {
		display: block;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--links .header__language {
		border-left: none;
	}
	
	.ct__header.ct__header--mobile {
		display: none;
	}

	.ct__header.ct__header--menu-mobile {
		display: none;
	}

	.ct__header.ct__header--mobile-extra {
		display: none;
	}
}

@media screen and (min-width: 1440px) {

	.ct__button.button--fill {
		height: 61px;
		min-width: 171px;
	}

	.ct__header.ct__header--default {
		padding-left: 0;
		padding-right: 0;
	}

	.ct__header.ct__header--info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-left: 0;
		padding-right: 0
	}

	.ct__header.ct__header--info .ct__header--store-info {
		margin: 0 4.5rem;
		width: 100%
	
	}
			
	.ct__header.ct__header--info .ct__header--store-info .arrow--down {
		display: none;
	}
				
	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours.ct__header--hours--all {
		bottom: -5rem;
		width: auto;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .header__current-hours {
		display: block;
	}

	.ct__header.ct__header--info .ct__header--store-info .ct__header--hours .menu--active.ct__header--hours--all {
		-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
	}

	.ct__header.ct__header--info .ct__header--links {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}

@media screen and (min-width: 1563px) {

	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown.nav--scrolling-top {
		top: 31px !important;
	}
}

@media screen and (min-width: 1663px) {

	.ct__header.ct__header--default .ct__header--shop .ct__header--shop-link .ct__header--dropdown {
		display: -ms-grid;
		display: grid;
		max-width: calc(100% - 214px);
		top: 135px;
	}

	.ct__header.ct__header--default .form--search {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
@media screen and (max-width: 1200px) {

	.ct__header.header--am {
		padding: 0 32px;
	}
}

@media screen and (max-width: 768px) {

	.ct__header.header--am .inner--menu .top--level > li > a {
		margin-right: 20px
	}

	.ct__header.header--am .inner--menu .top--level > li > a:hover,
	.ct__header.header--am .inner--menu .top--level > li > a:focus {
		display: unset;
	}

	.ct__header.header--am .inner--menu .top--level > li:hover .sub--level, .ct__header.header--am .inner--menu .top--level > li:focus .sub--level, .ct__header.header--am .inner--menu .top--level > li.active .sub--level {
		border-bottom: none;
		-webkit-box-shadow: none;
				box-shadow: none;
		height: 0;
		padding: 0;
	}

	.ct__header.header--am .inner--menu .top--level > li:nth-child(7),
	.ct__header.header--am .inner--menu .top--level > li:nth-child(8) {
		display: none;
	}
}

/* stylelint-enable */
